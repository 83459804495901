import React from 'react';
import Home from 'pages/home/Home';
import Terms from 'pages/terms/Terms';
import Registration from 'pages/registration/Registration';
import Login from 'pages/login/Login';
import PhoneVerification from 'pages/phone_verification';
import PhoneVerificationFindID from 'pages/phone_verification/FindID';
import PhoneVerificationFindPassword from 'pages/phone_verification/FindPassword';
import FindID from 'pages/forgot/FindID';
import FindPassword from 'pages/forgot/FindPassword';
import FindIDResult from 'pages/forgot/FindIDResult';
import FindPasswordResult from 'pages/forgot/FindPasswordResult';
import RegistrationDone from 'pages/registration_done/RegistrationDone';
import Forgot from 'pages/forgot';
import ChangePassword from 'pages/change_password';


const publicRoutes = [
	{ path: "/", component: <Home /> },
	{ path: "/login", component: <Login /> },
	{ path: "/change-password", component: <ChangePassword /> },
	{ path: "/phone-verify", component: <PhoneVerification /> },
	{ path: "/phone-verify/find-id", component: <PhoneVerificationFindID /> },
	{ path: "/phone-verify/find-password", component: <PhoneVerificationFindPassword /> },
	{ path: "/find-id", component: <FindID /> },
	{ path: "/find-password", component: <FindPassword /> },
	{ path: "/find-id/result", component: <FindIDResult /> },
	{ path: "/find-password/result", component: <FindPasswordResult /> },
	{ path: "/terms", component: <Terms /> },
	{ path: "/registration", component: <Registration /> },
	{ path: "/registration-done", component: <RegistrationDone /> },
	{ path: "/forgot", component: <Forgot />}
]

export { publicRoutes }