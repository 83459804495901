import React, { useCallback, useEffect, useState } from "react";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import { useNavigate } from "react-router-dom";
import { useVerificationInfoStore, defaultVerificationInfo } from "store/verificationInfo";

function FindPassword() {
  const navigate = useNavigate();
  const { verificationInfo, setVerificationInfo } = useVerificationInfoStore();
  const [id, setId] = useState("");

  const saveId = useCallback(() => {
    setVerificationInfo({ ...verificationInfo, id });
  }, [id]);

  const confirmButtonAction = async () => {
    saveId();
  }

  useEffect(() => {
    if (JSON.stringify(verificationInfo) !== JSON.stringify(defaultVerificationInfo)) {
      navigate("/phone-verify/find-password");
    }
  }, [verificationInfo])

  return (
    <>
      <div className="gameFi_upper_dark_bg">
        <section>
          <div className="registration_page_top">
            <img src={CenterLogo} alt="" />
            <p className="registration_title">비밀번호 찾기</p>
          </div>
          <div className="col-lg-3 mx-auto registration_page">
            <div className="form-group">
              <div className="text-center pb-md-5">
                <label>비밀번호를 재설정하시려는 계정의 아이디를 입력해주세요.</label>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="아이디를 입력해주세요."
                  autoFocus
                  name="username"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
                <button onClick={confirmButtonAction} className="enter_btn">
                  확인
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default FindPassword;
