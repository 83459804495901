import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import MobilePhone from "../../assets/images/home/mobile_img_2.png";
import MobileLogo from "../../assets/images/home/mobile_logo.png";
import CarouselImg from "../../assets/images/home/carousel_img.png";
import YoutubeLogo from "../../assets/images/home/youtube_logo.png";
import YoutubeImg from "../../assets/images/home/youtube_img.png";
import DownloadApp from "../../assets/images/home/download_appstore.png";
import DownloadGoogle from "../../assets/images/home/download_googleplay.png";
import DownloadWindows from "../../assets/images/home/download_windows.png";
import GameListNpoker from "../../assets/images/home/game_list_npoker.jpeg";
import GameListNpokerSlots from "../../assets/images/home/game_list_npoker_slots.jpeg";

import { FaGooglePlay } from "react-icons/fa";
import { FaAppStoreIos } from "react-icons/fa";
import { RiComputerFill } from "react-icons/ri";

import PlayVideo from "../../assets/images/home/play_video.mp4";

import NpokerSetup from "../../assets/images/home/Npoker_Setup.msi";
import NpokerSlotSetup from "../../assets/images/home/NpokerSlot_Setup.msi";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import SimpleBar from 'simplebar-react';
// import 'simplebar-react/dist/simplebar.min.css';

function Home() {
  var settings = {
    dots: false,
    autoplay: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <div className="  ">
     
        <div className="main_banner">
          <div className="container">
            <div className="row">
              <div className=" col-lg-12 text-center ">
                <div className="banner_inner_bg">
                  <h1>
                    당신만을 위한! 내 손안에<span> 카지노!</span>
                  </h1>
                  <div className="mobile_view">
                    <p>당신의! 당신에 의한! 당산만을 위한!</p>
                    <h1>특별한 카지노!!</h1>
                  </div>
                </div>
                <div className="video_area">
                  <img src={MobilePhone} className="mobile_img" alt="" />
                  <video
                    style={{
                      maxWidth: "100%",
                      width: "480px",
                      margin: "0 auto",
                    }}
                    playsInline
                    loop
                    controls
                    muted
                    alt="All the devices"
                    src={PlayVideo}
                    ref={videoEl}
                  />
                </div>
              
              </div>
            </div>
          </div>
        </div>

        <div className="home_banner_002">
          {localStorage.getItem("token") &&
             <div className="container">
             <div className="row">
               <div className="col-lg-3"></div>
               <div className="col-lg-3">
                 <div className="game-list-thum  card">
                   <a href="https://www.nplayshop.com/game/npokerpc" target="_blank"  className="thumnail">
                     <img
                       src={GameListNpoker}
                       className="card-img-top"
                       alt="..."
                     />
                   </a>
                   <div className="card-body">
                     <h5 className="card-title">엔포커</h5>
                     <p className="card-text">
                       카드 게임의 꽃! 7포커와 로우바둑이부터 카지노에서 즐길 수
                       있는 바카라, 홀덤까지!
                     </p>
                     <div className="card-button-area">
                     <a href="https://play.google.com/store/apps/details?id=com.sjenm.Poker" target="_blank"  className="card-button">
                         <FaGooglePlay />
                         Google Play
                       </a>
                       <a href="https://apps.apple.com/kr/app/%EC%97%94%ED%8F%AC%EC%BB%A4-%EC%B9%B4%EC%A7%80%EB%85%B8/id1597421826" target="_blank"  className="card-button">
                         <FaAppStoreIos />
                         App Store
                       </a>
                       <a href={NpokerSetup} download className="card-button">
                         <RiComputerFill />
                         PC
                       </a>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-lg-3">
                 <div className="game-list-thum  card ">
                   <a href="#" className="thumnail">
                     <img
                       src={GameListNpokerSlots}
                       className="card-img-top"
                       alt="..."
                     />
                   </a>
                   <div className="card-body">
                     <h5 className="card-title">엔포커슬롯</h5>
                     <p className="card-text">
                       최강 인기 슬롯 총집합!! <br />
                       오프라인 감성 그대로 슬롯을 즐기세요!!
                     </p>
                     <div className="card-button-area">
                       <a href="https://play.google.com/store/apps/details?id=games.monsternplay.npoker.slot" target="_blank"  className="card-button">
                         <FaGooglePlay />
                         Google Play
                       </a>
                       <a href={NpokerSlotSetup} download className="card-button">
                         <RiComputerFill />
                         PC
                       </a>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
          }
         
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="home_banner_text pt-5">
                  <img src={MobileLogo} className="mobile_logo" alt="" />
                  <h1>
                    <span> 7포커와 로우바둑이</span> 부터 <br />
                    카지노에서 즐길 수 있는<span> 바카라, 블랙잭</span>까지{" "}
                    <br />
                    언제 어디서든 당신의 운을 시험해 보세요!
                  </h1>
                </div>

                <div className="news_bg mt-5">
                  <Slider {...settings}>
                    <div>
                      <img src={CarouselImg} alt="" />
                    </div>
                    <div>
                      <img src={CarouselImg} alt="" />
                    </div>
                    <div>
                      <img src={CarouselImg} alt="" />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_banner_003">
          <div className="container">
            <div className=" pt-5">
              <a href="https://www.youtube.com/@nplay5829" target="_blank">
                {" "}
                <img src={YoutubeLogo} alt="YoutubeLogo" />
              </a>
            </div>
            <div className="row">
              <div className="col-lg-6 text-center mb-5">
                <a
                  href="https://www.youtube.com/watch?v=ScddgGy_Krw"
                  target="_blank"
                >
                  {" "}
                  <img src={YoutubeImg} alt="" />
                </a>
              </div>
              <div className="col-lg-6 text-center mb-5">
                <a
                  href="https://www.youtube.com/watch?v=KdRqtIg3HvI&t=6s"
                  target="_blank"
                >
                  <img src={YoutubeImg} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
