import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import UserRegistration from "../../assets/images/registration/user_registration.svg";
import Logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { clearTemporaryStorage } from "helpers/helpers";

function Header(props) {
  const navigate = useNavigate();
  const [loadingModal, setLoadingModal] = useState(false);
  
  const changePassword = () => {
    navigate('change-password');
  }

  async function logout() {
    clearTemporaryStorage();
    navigate("/");
    return;
  }

  return (
    <>
      <nav className="sticky fixed-top navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link "
                  target="_blank"
                  href="https://www.nplayshop.com/Customer/notice"
                >
                  공지사항
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://www.nplayshop.com/Event/list"
                >
                  이벤트
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link "
                  target="_blank"
                  href="https://www.nplayshop.com/game/holdem"
                >
                  가이드
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://www.nplayshop.com/Customer/qna"
                >
                  문의하기
                </a>
              </li>
            </ul>
            {localStorage.getItem("token") ? (
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {localStorage.getItem("username")}
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a onClick={() => changePassword()} className="dropdown-item" href="">
                      비밀번호 변경
                    </a>
                  </li>
                  <li>
                    <a onClick={() => logout()} className="dropdown-item" href="#">
                      로그아웃
                    </a>
                  </li>

                </ul>
              </li>
            ) : (
              <form className="d-flex ">
                <a href="/login" className="header_registration nav-link">
                  로그인
                </a>
                <a href="/terms" className="header_registration nav-link">
                  <img src={UserRegistration} alt="" />
                  회원가입
                </a>
              </form>
            )}
          </div>
        </div>
      </nav>

      <Modal
        {...props}
        size="cd"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={loadingModal}
        onHide={() => setLoadingModal(false)}
        className="loadingModal"
      >
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Modal>
    </>
  );
}

export default Header;
