import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { useStore } from "zustand";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/css/bootstrap.css'
import './assets/css/iconscout.css'
import './assets/css/style.css'
import { loaderStore } from "store/loader";
import Index from "./routes/index"



function App() {
	const { loader } = useStore(loaderStore);

	return (
		<>
			<Router>
				<Index />
			</Router>

			<ToastContainer
				position="bottom-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				theme="colored"
			/>

			{loader &&
				<div className="position-absolute top-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
					<div className="spinner-border" style={{ color: "white" }} role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			}
		</>
	);
}

export default App;
