import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import VerificationImg from "../../assets/images/registration/verification.png";
import { useVerificationInfoStore, defaultVerificationInfo } from "store/verificationInfo";

function FindID() {
  const navigate = useNavigate();
  const { verificationInfo } = useVerificationInfoStore();

  useEffect(() => {
    if (JSON.stringify(verificationInfo) === JSON.stringify(defaultVerificationInfo)) {
      alert("본인인증이 정상적으로 완료되지 않았습니다. 다시 시도해주세요.");
      navigate("/phone-verify/find-id");
    }
  }, [verificationInfo]);

  return (
    <>
      <div className="gameFi_upper_dark_bg min-h-100vh">
        <section>
          <div className="registration_page_top">
            <img src={CenterLogo} alt="" />
            <p className="registration_title">휴대폰 본인인증</p>
          </div>
          <div></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="text-center mb-5">
                  <img src={VerificationImg} alt="" />
                </div>
                <p className="text-white text-center">
                  - 본인인증은 아이디 찾기 이외 어떠한 용도로도 사용되지 않습니다.
                </p>
                <button className="enter_btn">
                  확인
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default FindID;
