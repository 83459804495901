import React, { useEffect } from "react";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import VerificationImg from "../../assets/images/registration/verification.png";
import { useNavigate } from "react-router-dom";
import { defaultVerificationInfo, useVerificationInfoStore } from "store/verificationInfo";

function PhoneVerification() {
  const navigate = useNavigate();
  const { verificationInfo, setVerificationInfo } = useVerificationInfoStore();

  function handlePopup() {
    window.open(
      process.env.REACT_APP_API_URL + "/danal/ready",
      "about",
      "top=100, left=100, width=500,height=800,scrollbars=yes"
    );
  };

  const saveVerificationInfo = (event) => {
    if (event.data?.danalResult) {
      const jsonString = event.data?.danalResult;
      try {
        const data = JSON.parse(jsonString);
        setVerificationInfo({
          dob: data.dob,
          name: data.name,
          ci: data.ci,
          di: data.di,
          sex: data.sex === "1" ? "M" : "F",
          phone: data.phone,
        })
      } catch (error) {
        // Handling errors that might occur during parsing
        console.error("Error parsing JSON:", error);
      }
    }
  };

  window.addEventListener("message", saveVerificationInfo);

  useEffect(() => {
    return () => {
      window.removeEventListener("message", saveVerificationInfo)
    };
  }, []);

  useEffect(() => { 
    if(JSON.stringify(verificationInfo) !== JSON.stringify(defaultVerificationInfo)){
      navigate("/registration");
    }
  }, [verificationInfo]);

  return (
    <>
      <div className="gameFi_upper_dark_bg min-h-100vh">
        <section>
          <div className="registration_page_top">
            <img src={CenterLogo} alt="" />
            <p className="registration_title">휴대폰 본인인증</p>
          </div>
          <div></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="text-center mb-5">
                  <img src={VerificationImg} alt="" />
                </div>
                <p className="text-white">
                  - 본인인증은 이벤트 혜택 중복 지급 체크 이외 어떠한 용도로도
                  사용되지 않습니다.
                </p>
                <p className="text-white">
                  - 본인인증 시 성인인증도 동시에 이루어집니다
                </p>
                <button onClick={handlePopup} className="enter_btn">
                  확인
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PhoneVerification;
