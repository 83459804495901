import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { publicRoutes } from './routes'

import NoAuthLayout from '../containers/Layout';

function createPublicRoutes() {
	return publicRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}

const Index = () => {
	return (
		<Routes>
			<Route element={<NoAuthLayout />}>
				{createPublicRoutes()}
			</Route>
		</Routes>
	)
}

export default Index;