import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultVerificationInfo, useVerificationInfoStore } from "store/verificationInfo";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import { POST } from "helpers/api";

function FindPasswordResult() {
  const navigate = useNavigate();
  const { verificationInfo, clearVerificationInfo } = useVerificationInfoStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const toLogin = () => {
    navigate("/login");
  }

  const resetPassword = async () => {
    const { id, ci, di } = verificationInfo;
    const res = await POST('/user/reset-password', {
      username: id,
      ci,
      di,
    });
    setLoading(false);
    clearVerificationInfo();
    if (res.code !== 200) {
      setError(true);
    }
  }

  useEffect(() => {
    if (JSON.stringify(verificationInfo) === JSON.stringify(defaultVerificationInfo)) {
      alert("잘못된 접근입니다.");
      navigate("/find-password");
    }
    resetPassword();
  }, []);

  return (
    <>
      <div className="gameFi_upper_dark_bg min-h-100vh">
        <section>
          <div className="registration_page_top">
            <img src={CenterLogo} alt="" />
            <p className="registration_title">비밀번호 재설정</p>
          </div>
          <div></div>
          <div className="container">
            {
              loading && (
                <div className="row">
                  <div className="col-lg-6 mx-auto">
                    <div className="text-center">
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">비밀번호 재설정중입니다...</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              error ?
                (<div className="row">
                  <div className="col-lg-6 mx-auto">
                    <p className="text-white text-center">
                      문제가 발생하였습니다.
                      <br />
                      고객센터로 문의해주시기 바랍니다.
                    </p>
                  </div>
                </div>)
                :
                (<div className="row">
                  <div className="col-lg-6 mx-auto">
                    <p className="text-white text-center">
                      비밀번호가 성공적으로 재설정되어 등록하신 이메일로 임시 비밀번호가 발송되었습니다.
                    </p>
                    <button onClick={toLogin} className="enter_btn">
                      로그인
                    </button>
                  </div>
                </div>)
            }
          </div>
        </section>
      </div>
    </>
  );
}

export default FindPasswordResult;
