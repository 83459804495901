import React, { useEffect, useState } from "react";
import CenterLogo from "../../assets/images/registration/npoker_logo.png";
import VerificationImg from "../../assets/images/registration/verification_success.png";
import { useSelector } from "react-redux";

function ConnectWallet() {
  const [createUser, setCreateUser] = useState({
    userId: "",
    password: "",
    nickname: "",
    joinIp: "",
    joinSite: "nplayshop",
    name: "",
    sex: "",
    email: "",
    phone:"",
    phoneAuth: "",
    CI:"",
    DI: "",
    birthday:"",
    device: "",
    DIRoute:"PHONE",
  });

  return (
    <>
      <div className="gameFi_upper_dark_bg min-h-100vh">
        <section>
          <div className="registration_page_top">
            <img src={CenterLogo} alt="" />
            <p className="registration_title">회원가입 완료</p>
              <h4 className="text-white mb-4">엔포커 회원가입을 환영합니다.</h4>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="text-center mb-5">
                  <img src={VerificationImg} alt="" />
                </div>
                <button className="enter_btn">로그인</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ConnectWallet;
